import * as React from 'react';
import * as Styled from './homePromoBannerStyle';
import { Col, Row } from 'react-bootstrap';
import isExternalUrl, { addTrailingSlash, isExternalUrlHref } from '../../utils';

const HomePromoBanner = ({ homeBanner }) => {
  return (
    <Styled.PromoBannerContainer>
      <Styled.PromoBannerInnerContainer fluid className="p-0">
        <Row className="g-0">
          <Styled.Column>
            <Styled.AnchorTag
              href={
                isExternalUrlHref(homeBanner?.path)
                  ? homeBanner?.path
                  : addTrailingSlash(
                      process.env.GATSBY_DOMAIN_URL + homeBanner?.path
                    )
              }
              target={isExternalUrl(homeBanner?.path) ? '_blank' : '_self'}
            >
              <img src={homeBanner?.image?.file?.url} alt="promo" />
            </Styled.AnchorTag>
          </Styled.Column>
        </Row>
      </Styled.PromoBannerInnerContainer>
    </Styled.PromoBannerContainer>
  );
};

export default HomePromoBanner;
